<template>
  <div class="app app-project">
    <van-pull-refresh v-model="isRefreshing" @refresh="onRefresh" style="height: 100%;width: 100%;overflow-y: auto;">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad()">
        <div class="demo-project flex">
          <template v-if="projects.length">
            <div v-for="(p,index) in projects" :key="p.id" class="item gap-f-10" :class="{collapsed: !p.expanded}">
              <div class="header padding-05" @click="p.expanded = !p.expanded">
                <div class="progress" :style="{width: `${p.taskFinished / p.taskTotal * 100}%`}"></div>
                <div class="h inner">
                  <div class="name flex text-no-wrap padding-05">{{p.proName}}</div>
                  <div class="no-flex op-80 padding-05" v-if="!p.expanded">{{Number((p.progress * 100).toFixed(4))}}%</div>
                  <div class="padding-05 op-60" :class="p.expanded ? 'fa fa-angle-up' : 'fa fa-angle-down'"></div>
                </div>
              </div>
              <transition name="van-fade">
                <div class="content padding-10" v-if="p.expanded" @click="toDetail(p)">
                  <div class="desc">{{ p.content }}</div>
                  <div class="fs-mini op-80 gap-1x">项目进度</div>
                  <van-progress class="gap-10" color="#607ae3" :percentage="Number((p.progress * 100).toFixed(4))" />
                  <div class="h sb gap-1x">
                    <count-to title="任务完成数" :end-val="p.finishTaskNumber" :precision="0" title-class="fs-mini" content-class="bold fc-s fs-huge" align="s" />
                    <div class="sep-v"></div>
                    <count-to title="任务总数" :end-val="p.taskNumber" :precision="0" title-class="fs-mini" content-class="bold fs-huge" align="s" />
                    <div class="sep-v"></div>
                    <count-to title="实验总数" :end-val="p.expNumber" :precision="0" title-class="fs-mini" content-class="bold fs-huge" align="s" />
                    <div class="sep-v"></div>
                    <count-to title="风险总数" @click.native.stop="toRiskList(p.id)" :end-val="p.riskNumber" :precision="0" title-class="fs-mini" content-class="bold fs-huge fc-e" align="s" />
                  </div>
                  <div class="fs-mini op-80 gap-1x">项目成员</div>
                  <staff-wall type="project" :target-id="p.id" show-total class="gap-10" />
                </div>
              </transition>
            </div>
          </template>
          <van-empty v-else description="暂无项目信息" />
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
  
<script>
import { getProjectList } from "@/api/project";
import StaffWall from "../assembly/wall";
export default {
  components: { StaffWall },
  data() {
    return {
      projects: [],
      isRefreshing: false,
      loading: false,
      finished: false,
      current: 1,
      size: 10,
    };
  },
  mounted() {
    document.body.classList.remove("is-mobile");
    document.body.classList.add("is-mobile");
  },
  methods: {
    onRefresh() {
      // 下拉刷新数据
      this.projects = [];
      this.current = 1;
      this.finished = false;
      this.onLoad();
      this.isRefreshing = false;
    },
    onLoad(type) {
      this.loading = true;
      try {
        getProjectList({ current: this.current, size: this.size }).then(
          (res) => {
            this.projects = [...this.projects, ...res.content].map((r) => {
              r.expanded = true;
              return r;
            });
            this.current += 1;
            this.loading = false;
            this.finished = this.projects.length >= res.totalElements;
          }
        );
      } catch (error) {
        this.loading = false;
        this.$toast.fail("加载失败");
      }
    },
    toDetail(item) {
      this.$router.push({
        path: "/project/detail/" + item.id,
      });
    },
    toRiskList(id) {
      this.$router.push({
        name: "ProjectRisk",
        params: {
          projectId: id,
        },
      });
    },
  },
  beforeDestroy() {
    document.body.classList.remove("is-mobile");
  },
};
</script>
  
  <style lang="scss">
.demo-project {
  > .item {
    border-radius: 10px;

    > .header {
      position: relative;
      line-height: 1.35rem;
      background-color: var(--selected-background-color-darken);
      color: var(--selected-color-darken);
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      transition: all 0.3s;

      > .inner {
        position: relative;
        z-index: 2;

        > .name {
          font-size: 1.15rem;
          font-weight: bold;
        }
      }

      > .progress {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        transform-origin: 0 0;
        transform: scaleX(0);
        background-color: rgba(0, 0, 0, 0);
        transition: all 0.3s;
      }
    }

    > .content {
      background-color: var(--background-color-lightest);
      border-radius: 0 0 10px 10px;

      .desc {
        text-align: justify;
      }
    }

    &.collapsed {
      > .header {
        border-radius: 10px;
        > .progress {
          transform: scaleX(1);
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
</style>